import { dealerList } from '~/modules/investment-consultant/orderBox/MutipleDealerQuickFuturesOrderBox/Function/dealerInfoList';
export function checkDealerNameInLocalStorage(agent) {
    const localStorageName = agent + '-defaultDealer';
    const storedDealerName = localStorage.getItem(localStorageName);
    if (storedDealerName) {
        // 遍历检查存储的数据中是否包含dealerList标签
        const dealer = dealerList.find(item => item.name === storedDealerName);
        // 如果有对应的dealerList数据，则返回第一个資料
        // 否则返回null
        return dealer || null;
    }
    return null;
}
export function saveDealerNameInLocalStorage(agent, dealerName) {
    //為了避免不同投顧產品的名稱重複，所以在localStorage的key值加上投顧產品名稱
    const localStorageName = agent + '-defaultDealer';
    localStorage.setItem(localStorageName, dealerName);
}
