import { dealerUrlConfig, } from '~/modules/investment-consultant/orderBox/MutipleDealerQuickFuturesOrderBox/Function/dealerInfoList';
export function getDealerTxOrderUrl(
//先限制只能下單台指期
symbol, dealerName, longShort, agentName) {
    const dealerConfig = dealerUrlConfig[dealerName].tx;
    if (!dealerConfig) {
        throw new Error('Unsupported dealer name');
    }
    // 根據配置生成 URL
    const baseUrl = dealerConfig.baseUrl;
    const params = dealerConfig.params(symbol, longShort, agentName);
    return baseUrl + params;
}
export function getDealerStockFutureOrderUrl(symbol, dealerName, longShort, agentName) {
    const dealerConfig = dealerUrlConfig[dealerName].stockfuture;
    if (!dealerConfig) {
        throw new Error('Unsupported dealer name');
    }
    // 根據配置生成 URL
    const baseUrl = dealerConfig.baseUrl;
    const params = dealerConfig.params(symbol, longShort, agentName);
    return baseUrl + params;
}
